import React, { useState } from 'react';
import { getFontWeight } from '../../helpers/utils';

import styled from 'styled-components';

const variants = {
  size_44: {
    height: '44px',
    padding: '12px 16px',
  },
  size_32: {
    height: '32px',
    padding: '8px 12px',
  },
};

const MultiChooseElement = ({
  name,
  lexicon,
  font,
  is_required = false,
  value,
  defaultValue,
  onChange = () => {},
  placeholder,
  className = '',
  error,
  disabled = false,
  error_text = '',
  extra_text = '',
  autofill = 'off',
  sub_name = '',
  type = 'text',
  variant = 'size_44',
  inputRef,
  list,
  extra_text_before = '',
  extra_text_after = '',
  attributes,
}) => {
  return (
    <MultiChooseElementWrapper className={className} variant={variants[variant]}>
      {name && (
        <div className="mb-1 one-line">
          <span
            className="inter-500-text natural-900-text"
            style={{
              color: attributes?.font?.color,
              fontSize: attributes?.font?.size,
              fontWeight: getFontWeight(attributes?.font?.style),
              fontFamily: attributes?.font?.name,
            }}>
            {name}
          </span>
          {/* {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>} */}
          {is_required && (
            <span
              className="required-star"
              style={{
                color: attributes?.font?.color,
                fontSize: attributes?.font?.size,
                fontWeight: getFontWeight(attributes?.font?.style),
                fontFamily: attributes?.font?.name,
              }}>
              *
            </span>
          )}
        </div>
      )}
      <div className="flex wrap">
        {list?.choices?.map(c => (
          <div
            key={c.id}
            className="no-select flex items-center cursor mr-3"
            onClick={() =>
              onChange(
                lexicon,
                value.includes(c.name) ? value?.filter(v => v !== c.name) : value ? [...value, c.name] : [c.name],
              )
            }>
            <input type="checkbox" id="scales" name="scales" checked={value?.includes(c.name)} />
            <label
              className="ml-1"
              style={{
                color: font?.color,
                fontSize: font?.size,
                fontWeight: getFontWeight(font?.style),
                fontFamily: font?.name,
              }}>
              {c.name}
            </label>
          </div>
        ))}
      </div>
    </MultiChooseElementWrapper>
  );
};

const MultiChooseElementWrapper = styled('div')`
  .input-element {
    outline: none;
    height: ${({ variant }) => variant.height};
    border: 1px solid ${({ theme }) => theme.lightGrey};
    border-radius: 6px;
    padding: ${({ variant }) => variant.padding};

    &::placeholder {
      color: ${({ theme }) => theme.natural_400};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .input-before-element {
    padding-left: 24px !important;
  }
  .percent-before {
    top: 0;
    display: flex;
    align-items: center;
    left: 10px;
    height: 100%;
  }
  .percent-after {
    top: 0;
    display: flex;
    align-items: center;
    right: 10px;
    height: 100%;
  }

  .textarea-element {
    height: 104px;
    resize: none;
  }

  .extra-text {
    right: 20px;
    padding: 12px 0;
  }

  .input-error {
    border-color: ${({ theme }) => theme.error_500};

    &:hover {
      border-color: ${({ theme }) => theme.error_500};
    }

    &:focus {
      border-color: ${({ theme }) => theme.error_500};
      box-shadow: none;
    }
  }
`;

export default MultiChooseElement;
