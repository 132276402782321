import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  background: #000000;

  .main-panel {
    max-width: 740px;
  }

  .wrapper {
    align-items: center;
    height: fit-content;
  }
`;
