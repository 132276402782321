import { nanoid } from 'nanoid';
import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import WidgetComponent from '../../components/widget-component';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription } from '../../helpers/utils';
import {
  createJob,
  getFormConfigs,
  getFormDetails,
  getFormPanelWidgets,
  getFormPanels,
} from '../../store/features/quoteSlice';
import { getJobTypes } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';
import { MainWrapper } from '../../styles/pages/main.styled';
import Button from '../../components/common/button/button';
import styled from 'styled-components';

const Main = ({ form }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const form_id = form || id;
  const [formDetails, setFormDetails] = useState({});
  const [formPanels, setFormPanels] = useState([]);
  const [formConfigs, setFormConfigs] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState({});
  const [quoteDetails, setQuoteDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [currentStep, setCurrentStep] = useState('QUOTE_DETAILS');
  const [loading, setLoading] = useState(false);
  const [components, setComponensts] = useState([]);
  const [values, setValues] = useState({});
  const [success, setSuccess] = useState(false);

  const { api_key } = formDetails || {};
  const { panel_color, transparency } = selectedPanel || { panel_color: '#FFFFFF' };

  const resetState = () => {
    setQuoteDetails({});
    setCustomerDetails({});
    setCurrentStep('QUOTE_DETAILS');
    setValues({});
  };

  const fetchFormDetails = form_id => {
    dispatch(getFormDetails({ form_id: form_id }))
      .then(data => setFormDetails(data))
      .catch(error => {
        console.error(error);
        dispatch(addToast({ error: true, text: 'Failed to fetch form details', id: nanoid() }));
      });
  };

  const fetchFormPanels = form_id => {
    dispatch(getFormPanels({ api_key, form_id }))
      .then(data => {
        setFormPanels(data);
        setSelectedPanel(data[0]);
      })
      .catch(error => {
        setFormPanels([]);
      });
  };

  useEffect(() => {
    if (selectedPanel?.id) {
      dispatch(getFormPanelWidgets({ api_key, panel_id: selectedPanel.id })).then(w => {
        setComponensts(
          w
            .map(c => c.components)
            .flat()
            .sort((a, b) => (a.row > b.row ? 1 : -1))
            .reduce(
              (acc, val) => {
                if (!acc[val.row]) acc[val.row] = [];
                acc[val.row]?.push(val);
                return acc;
              },
              [[]],
            ),
        );
      });
    }
  }, [selectedPanel, api_key]);

  const fetchFormConfigs = form_id => {
    dispatch(getFormConfigs({ api_key, form_id: form_id }))
      .then(data => setFormConfigs(data))
      .catch(error => {
        console.error(error);
        setFormConfigs([]);
        dispatch(addToast({ error: true, text: 'Failed to fetch form configs', id: nanoid() }));
      });
  };

  useEffect(() => {
    fetchFormDetails(form_id);
  }, [form_id]);

  useEffect(() => {
    if (api_key) {
      fetchFormPanels(form_id);
      fetchFormConfigs(form_id);
      dispatch(getJobTypes({ api_key })).then(types => setQuoteDetails({ ...quoteDetails, type: types?.[0] }));
    }
  }, [api_key]);

  const onGetQuote = () => {
    setCurrentStep('CUSTOMER_DETAILS');
    setSelectedPanel(formPanels[1] || {});
  };

  const getConfigIdByType = configType => {
    const configData = formConfigs.find(config => config.tag === configType);
    const { config } = configData || {};
    if (!config) {
      return null;
    }
    return { id: config.id };
  };

  const getConfigType = configType => {
    const configData = formConfigs.find(config => config.tag === configType);
    const { config } = configData || {};
    if (!config) {
      return null;
    }
    return config.id;
  };

  const getJobDesctiption = services => {
    var description = '';
    if (services?.length > 0) {
      description += 'Requested - ' + services.join(', ');
    }
    return description;
  };
  const onDone = () => {
    const { bill, type } = quoteDetails || {};
    const { forename, surname, email, mobile, country_code, address, notes, services } = customerDetails || {};
    const { address_external_id, integration_id, ...restAddress } = address || {};
    const annual_electric_cost = bill ? bill.replaceAll('£', '') * 12 : 0;
    const annual_electric_kwh = annual_electric_cost > 0 ? annual_electric_cost / 0.29 : 0;
    const leadSource = getConfigIdByType('LEAD_SOURCE');
    const request = {
      job_type: {
        id: type?.id,
      },
      engagement: {
        parent: null,
        name: type?.name || '',
        project: getConfigIdByType('PROJECT'),
        campaign: getConfigIdByType('CAMPAIGN'),
        engagement_dates: null,
        engagement_value: null,
        quote: null,
        primary_contact: {
          title: '',
          forename: forename || '',
          surname: surname || '',
          language: null,
          timezone: null,
          date_of_birth: null,
          pronouns: null,
          gender: null,
          profession: null,
          company_name: null,
          contact_type: 'PERSON',
          parent: null,
          job_title: null,
          emails: [
            {
              value: email,
              is_primary: true,
              email_type: 'WORK',
            },
          ],
          phones:
            mobile?.length === 12
              ? [
                  {
                    contact_type: 'MOBILE',
                    contact_value: mobile?.replaceAll(' ', '')?.replaceAll('-', '').replace(/^0+/, ''),
                    is_primary: true,
                    phone_type: 'WORK',
                    country_code: country_code || '+44',
                  },
                ]
              : null,
          addresses: [
            {
              ...restAddress,
            },
          ],
          call_time: null,
          property_contact_type: 'THIRD_PARTY',
          shared_percentage: 0,
        },
        property: {
          ...restAddress,
          address_reference: {
            integration_id: integration_id,
            external_reference: address_external_id,
          },
          details: {
            annual_electric_cost: parseFloat(annual_electric_cost).toFixed(4),
          },
          property_class: {
            class_type: getConfigType('PROPERTY_TYPE') || 'RESIDENTIAL',
          },
        },
        workflow: getConfigIdByType('WORKFLOW'),
        agent: null,
        note: notes ? { note: notes } : null,
      },
      job_number: '',
      po_number: '',
      job_description: getJobDesctiption(services),
      lead_gen: leadSource ? { organization: leadSource } : null,
      partners: null,
    };
    setLoading(true);
    dispatch(createJob({ api_key, request }))
      .then(data => {
        setSuccess(true);
        resetState();
      })
      .catch(error => {
        const errorText = getErrorDescription(error, 'Failed to create job');
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (lexicon, value) => {
    setValues({ ...values, [lexicon]: value });
    if (lexicon === 'contact.forename') setCustomerDetails({ ...customerDetails, forename: value });
    if (lexicon === 'contact.surname') setCustomerDetails({ ...customerDetails, surname: value });
    if (lexicon === 'contact.email') setCustomerDetails({ ...customerDetails, email: value });
    if (lexicon === 'contact.mobile')
      setCustomerDetails({ ...customerDetails, country_code: value.country_code, mobile: value.value });
    if (lexicon === 'contact.address') setCustomerDetails({ ...customerDetails, address: value });
    if (lexicon === 'contact.notes') setCustomerDetails({ ...customerDetails, notes: value });
    if (lexicon === 'job.services') setCustomerDetails({ ...customerDetails, services: value });
  };
  return (
    <MainWrapper
      className="w-full main flex-column items-center flex-1"
      transparency={transparency}
      style={{ backgroundColor: panel_color }}>
      {success ? (
        <div
          className="flex-column items-center justify-center flex-1"
          style={{ maxWidth: formDetails?.attributes?.[0]?.panel_width / 2 }}>
          <label className="inter-700-text natural-900-text font-26">THANK YOU</label>
          <label className="inter-500-text natural-900-text font-20 mt-4 text-center">
            Your enquiry has been sent to our team and we will be in touch shortly to help revolutionise your power
            consumption.
          </label>
        </div>
      ) : (
        <div className="flex-column main-panel w-full" style={{ maxWidth: formDetails?.attributes?.[0]?.panel_width }}>
          <OrganisationContext.Provider value={{ api_key, getConfigIdByType }}>
            <div className="wrapper flex-1">
              {components.map((r, i) => (
                <GridWrapper key={i} className="grid widget-grid col-gap-3 mb-4" columns={r.length}>
                  {r
                    .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                    .map(c => (
                      <div key={c.id} className="flex-1 ">
                        <WidgetComponent
                          component={c}
                          onChange={onChange}
                          value={values[c.lexicon] || ''}
                          attributes={formDetails?.attributes?.[0]}
                        />
                      </div>
                    ))}
                </GridWrapper>
              ))}
            </div>
          </OrganisationContext.Provider>
          {components?.length > 0 && (
            <Button
              disabled={
                !customerDetails.forename ||
                !customerDetails.surname ||
                !customerDetails.email ||
                !customerDetails.address
              }
              size="customsize"
              loading={loading}
              label="Submit"
              className="flex primary lead-submit no-select flex-1 w-full cursor"
              color="#ffffff"
              style={{
                backgroundColor: formDetails?.attributes?.[0]?.color?.button,
                borderColor: formDetails?.attributes?.[0]?.color?.button,
              }}
              font={{ name: 'Lato', style: 'MEDIUM', size: '14px', color: '#ffffff' }}
              onClick={onDone}
            />
          )}
        </div>
      )}
    </MainWrapper>
  );
};

const GridWrapper = styled('div')`
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-row-gap: 16px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export default Main;
