import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { getFontWeight } from '../../helpers/utils';

const variants = {
  size_44: {
    height: '44px',
    padding: '12px 16px',
  },
  size_32: {
    height: '32px',
    padding: '8px 12px',
  },
  size_60: {
    height: '60px',
    padding: '7px 20px',
  },
};

const InputElement = ({
  name,
  is_required = false,
  value,
  defaultValue,
  lexicon,
  onChange = () => {},
  placeholder,
  className = '',
  error,
  disabled = false,
  error_text = '',
  extra_text = '',
  autofill = 'off',
  sub_name = '',
  type = 'text',
  variant = 'size_44',
  inputRef,
  extra_text_before = '',
  extra_text_after = '',
  attributes,
  font,
  rows,
}) => {
  const isTextArea = type === 'textarea';
  return (
    <InputElementWrapper className={className} variant={variants[variant]}>
      {name && (
        <div className="mb-1 one-line">
          <span
            style={{
              color: attributes?.font?.color,
              fontSize: attributes?.font?.size,
              fontWeight: getFontWeight(attributes?.font?.style),
              fontFamily: attributes?.font?.name,
            }}>
            {name}
          </span>
          {/* {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>} */}
          {is_required && (
            <span
              className="required-star"
              style={{
                color: attributes?.font?.color,
                fontSize: attributes?.font?.size,
                fontWeight: getFontWeight(attributes?.font?.style),
                fontFamily: attributes?.font?.name,
              }}>
              *
            </span>
          )}
        </div>
      )}
      <div className="relative">
        {isTextArea ? (
          <textarea
            className={classNames('w-full input-element textarea-element', error && 'input-error')}
            defaultValue={defaultValue}
            disabled={disabled}
            placeholder={placeholder}
            ref={inputRef}
            onChange={({ target: { value } }) => onChange(lexicon, value)}
            value={value}
            rows={rows}
            style={{
              color: font?.color,
              fontSize: font?.size,
              fontWeight: getFontWeight(font?.style),
              fontFamily: font?.name,
            }}
          />
        ) : (
          <>
            {extra_text_before && (
              <span className="percent-before inter-400-text font-14 natural-900-text absolute">
                {extra_text_before}
              </span>
            )}
            <input
              style={{
                color: font?.color,
                fontSize: font?.size,
                fontWeight: getFontWeight(font?.style),
                fontFamily: font?.name,
              }}
              autoComplete={autofill}
              className={classNames(
                ' w-full input-element',
                error && 'input-error',
                extra_text_before && 'input-before-element',
              )}
              defaultValue={defaultValue}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              ref={inputRef}
              type={type}
              onChange={({ target: { value } }) => onChange(lexicon, value)}
            />
            {extra_text_after && (
              <span className="percent-after inter-400-text font-14 natural-900-text absolute">{extra_text_after}</span>
            )}
          </>
        )}
        {extra_text && (
          <span className="extra-text inter-400-text font-14 natural-900-text absolute">{extra_text}</span>
        )}
      </div>
      {error && error_text && (
        <div>
          <span className="inter-400-text font-12 error-text">{error_text}</span>
        </div>
      )}
    </InputElementWrapper>
  );
};

const InputElementWrapper = styled('div')`
  .input-element {
    outline: none;
    height: ${({ variant }) => variant.height};
    border: none;
    border-radius: 6px;
    padding: ${({ variant }) => variant.padding};

    &::placeholder {
      color: ${({ theme }) => theme.natural_400};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .input-before-element {
    padding-left: 24px !important;
  }
  .percent-before {
    top: 0;
    display: flex;
    align-items: center;
    left: 10px;
    height: 100%;
  }
  .percent-after {
    top: 0;
    display: flex;
    align-items: center;
    right: 10px;
    height: 100%;
  }

  .textarea-element {
    height: auto;
    resize: none;
  }

  .extra-text {
    right: 20px;
    padding: 12px 0;
  }

  .input-error {
    border-color: ${({ theme }) => theme.error_500};
  }
`;

export default InputElement;
