import classNames from 'classnames';
import React, { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import styled, { useTheme } from 'styled-components';
import DownIcon from '../../../assets/images/arrow.svg';
import CloseIcon from '../../../assets/images/toast-close.svg';
import { getFontWeight } from '../../../helpers/utils';

const defaultCustomStyle = {
  control: {},
  valueContainer: {},
  menu: {},
  menuList: {},
  indicatorsContainer: {},
  dropdownIndicator: {},
  loadingIndicator: {},
  group: {},
};

const variants = {
  primary: {
    control: { height: '44px', borderRadius: '6px' },
  },
};

const Option = props => {
  const { innerProps, data, isSelected, isFocused, selectProps } = props;
  const { getOptionLabel, inputValue, isSearchable } = selectProps;

  return (
    <components.Option {...props} getStyles={() => {}}>
      <div
        className={classNames(
          'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
          isSelected && 'selected',
          isFocused && 'bg-lightgray-1',
        )}
        {...innerProps}>
        <span
          className={classNames(
            'flex-1 items-center inter-400-text natural-900-text option-text one-line',
            isSelected && 'inter-500-text',
          )}>
          {isSearchable ? (
            <Highlighter
              searchWords={[inputValue]}
              autoEscape={true}
              textToHighlight={getOptionLabel(data)}
              highlightTag={({ children, _highlightIndex }) => <strong className="highlighted-text">{children}</strong>}
            />
          ) : (
            getOptionLabel(data)
          )}
        </span>
      </div>
    </components.Option>
  );
};

const DropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props} className="mr-2">
      <img src={DownIcon} height={16} width={16} className={classNames(menuIsOpen ? 'rotate-270' : 'rotate-90')} />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return <img src={CloseIcon} height={16} width={16} className="error-text" onClick={props.clearValue} />;
};

const SearchableDropdown = ({
  placeholder = '',
  onChange = () => {},
  value = null,
  isMulti = false,
  name = '',
  sub_name = '',
  is_required = false,
  className = '',
  error = false,
  customStyle = defaultCustomStyle,
  customClass = false,
  menuPlacement = 'auto',
  inputValue = '',
  onInputChange = () => {},
  loadOptions,
  defaultAdditional = {},
  key = 'searchable_dropdown',
  isClearable = false,
  variant = '',
  isSearchable = false,
  loadOptionsOnMenuOpen = true,
  customComponent = {},
  classNamePrefix = '',
  font,
  size,
  ...rest
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const dropdownStyle = {
    container: baseStyles => ({
      ...baseStyles,
      ...customStyle.container,
    }),
    input: baseStyles => ({
      ...baseStyles,
      ...customStyle.input,
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      ...customStyle.singleValue,
    }),
    menuPortal: baseStyles => ({
      ...baseStyles,
      ...customStyle.menuPortal,
    }),
    control: (baseStyles, { selectProps: { menuIsOpen } }) => ({
      ...baseStyles,
      boxShadow: error ? `0px 0px 0px 4px #FEE2E2` : 'none',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '44px',
      cursor: 'pointer',
      borderColor: 'transparent',
      '&:hover': {
        boxShadow: error ? `0px 0px 0px 4px #FEE2E2` : 'none',
      },
      ...(variants[variant]?.control || {}),
      ...customStyle.control,
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontFamily: 'Inter',
      color: theme.natural_400,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      color: theme.natural_900,
      ...customStyle.indicatorsContainer,
    }),
    dropdownIndicator: baseStyles => ({
      ...baseStyles,
      ...customStyle.dropdownIndicator,
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      fontFamily: font.name,
      fontSize: font?.size,
      fontWeight: getFontWeight(font?.style),
      input: {
        height: 'auto',
      },
      ...customStyle.valueContainer,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '8px',
      overflow: 'hidden',
      zIndex: 11,
      ...customStyle.menu,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0px',
      maxHeight: '180px',
      ...customStyle.menuList,
    }),
    noOptionsMessage: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Inter',
      color: theme.natural_400,
    }),
    loadingIndicator: baseStyles => ({
      ...baseStyles,
      ...customStyle.loadingIndicator,
    }),
    group: baseStyles => ({
      ...baseStyles,
      padding: '0px',
      ...customStyle.group,
    }),
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    {
      page,
      merge,
      fetchFunction,
      hasMore,
      pageable,
      params = {},
      payload = {},
      formatOptions = null,
      defaultOptions = [],
    },
  ) => {
    if (!fetchFunction) {
      return {
        options: defaultOptions,
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
          ...payload,
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        optionContent = page === 0 ? defaultOptions.concat(optionContent) : optionContent;
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
        optionContent = defaultOptions.concat(optionContent);
      }
      let changedOptions = optionContent.map(option => ({ ...option, label: option.name, value: option.id }));
      changedOptions = formatOptions ? formatOptions(changedOptions) : changedOptions;

      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      console.log('error', error);
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  return (
    <SearchableDropdownWrapper className={classNames('w-full', className)} ref={containerRef} variant={size}>
      <AsyncPaginate
        isClearable={isClearable}
        isSearchable={isSearchable}
        key={key}
        openMenuOnClick={true}
        isMulti={isMulti}
        placeholder={placeholder}
        closeMenuOnSelect={!isMulti}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        loadOptions={loadOptions || fetchChildOpt}
        additional={defaultAdditional}
        classNamePrefix="searchable-select"
        hideSelectedOptions={false}
        loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
        onChange={option => onChange(option)}
        styles={dropdownStyle}
        menuPlacement={menuPlacement}
        tabSelectsValue={false}
        components={{
          Option,
          DropdownIndicator,
          ClearIndicator,
          ...customComponent,
        }}
        {...rest}
      />
    </SearchableDropdownWrapper>
  );
};

const SearchableDropdownWrapper = styled.div`
  .searchable-select__control {
    height: ${({ variant }) => variant.height};
    font-size: 16px;
  }
  .searchable-select__input {
    font-size: 16px;
  }
  .search-primary-select {
    .searchable-select__value-container--has-value {
      .searchable-select__input-container {
        display: none !important;
        height: 0px !important;
      }
    }
  }

  .select-option-primary {
    .title-one-line {
      width: 235px;
    }
  }

  .option-wrapper {
    .line-vertical {
      width: 1px;
      height: 12px;
      background-color: ${({ theme }) => theme.natural_300};
    }
    .vertical-last-line {
      display: none;
    }
  }

  .select__placeholder {
    display: flex;
    align-items: center;
  }

  .select__input {
    color: transparent !important;
  }

  .custom-input-search {
    .input {
      background-color: ${({ theme }) => theme.natural_50};
      border: 0;
    }
    .search-icon {
      top: 0px;
      left: 0px;
    }
  }
`;

export default SearchableDropdown;
