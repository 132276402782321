import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import AfternoonIcon from '../../assets/images/afternoon-icon.svg';
import AnytimeIcon from '../../assets/images/clock-icon.svg';
import EveningIcon from '../../assets/images/evening-icon.svg';
import MorningIcon from '../../assets/images/morning-icon.svg';
import Button from '../../components/common/button/button';
import PhoneInput from '../../components/common/phone-input';
import { ModalContext } from '../../context/ModalContext';
import { initModal } from '../../helpers/utils';

const RequestSurvey = () => {
  const { modal, setModal } = useContext(ModalContext);
  const { content } = modal || {};
  const { customTheme, api_key, token } = content || {};

  const [customerDetails, setCustomerDetails] = useState({ country_code: '+44', mobile: '', preferredCallTime: '' });
  const { country_code, mobile, preferredCallTime } = customerDetails || {};

  const onUpdateCustomerDetails = (key, value) => {
    setCustomerDetails({ ...customerDetails, [key]: value });
  };

  return (
    <RequestSurveyWrapper customTheme={customTheme}>
      <label className="inter-700-text font-28 pb-6 border-bottom w-full flex flex-1">Request Survey</label>
      <p className="inter-400-text natural-500-text py-6">
        Enter your phone number and preferred called time below and we will give you a call to schedule your survey in
        as soon as possible!
      </p>
      <div className="flex-column w-full">
        <div className="mb-1">
          <label className="inter-500-text natural-900-text">Mobile</label>
        </div>
        <PhoneInput
          selectedCountry={country_code || '+44'}
          setSelectedCountry={value => onUpdateCustomerDetails('country_code', value)}
          phone={mobile}
          setPhone={value => onUpdateCustomerDetails('mobile', value)}
        />
      </div>
      <div className="flex-column w-full mt-6">
        <div className="mb-2">
          <label className="inter-500-text natural-900-text">Preferred call time</label>
        </div>
        <div className="gap-2 mb-2 tw-grid tw-grid-cols-2 md:tw-grid-cols-4">
          {[
            {
              id: 1,
              label: 'Morning',
              Icon: MorningIcon,
              time: 'morning',
            },
            {
              id: 2,
              label: 'Afternoon',
              Icon: AfternoonIcon,
              time: 'afternoon',
            },
            {
              id: 3,
              label: 'Evening',
              Icon: EveningIcon,
              time: 'evening',
            },
            {
              id: 4,
              label: 'Anytime',
              Icon: AnytimeIcon,
              time: 'anytime',
            },
          ].map(({ Icon, id, label, time }) => (
            <div
              key={id}
              onClick={() => onUpdateCustomerDetails('preferredCallTime', time)}
              className={classNames(
                'cursor flex-1 flex-column items-center bg-white tw-duration-300 tw-transition-all radius-1_5 py-1_5 px-3',
                preferredCallTime === time ? 'border-blue primary-text' : 'border-natural-200 natural-700-text',
              )}>
              <div className="flex items-center justify-center pxy-1">
                <img src={Icon} height={16} width={16} />
              </div>
              <div>
                <label className="inter-500-text !tw-text-inherit font-12 line-height-20">{label}</label>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between col-gap-4 flex-1 items-end mt-10">
        <Button label="Cancel" className="primary-grey w-full" onClick={() => setModal(initModal)} size="large" />
        <Button
          label="Book call"
          className="primary btn-primary w-full"
          onClick={() => setModal(initModal)}
          size="large"
          disabled={!preferredCallTime || !mobile || mobile.length < 12}
        />
      </div>
    </RequestSurveyWrapper>
  );
};

const RequestSurveyWrapper = styled.div`
  width: min(456px, 100%);
  max-width: 456px;
  padding: 40px;

  .btn-primary {
    background-color: ${({ customTheme }) => customTheme.color.button};
    border-color: ${({ customTheme }) => customTheme.color.button};
  }
`;

export default RequestSurvey;
