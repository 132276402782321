import moment from 'moment';
import { getItemFromLocalStorage } from './localstorage';

export const initModal = {
  type: null,
  content: {},
};

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function capitalizeFirstLetterOfWords(str) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text.toLowerCase().replaceAll('_', replaceWith);
};

export const formatDate = unixDate => moment.unix(unixDate).format('D MMMM YYYY');

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const getUsersPermissions = () => {
  const user = getItemFromLocalStorage('user');
  return user?.api_tokens?.permissions || [];
};

export const getUserInitials = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}${userName?.[1]?.charAt(0)?.toUpperCase() || ''}`
    : forename || surname
    ? `${forename?.charAt(0)?.toUpperCase() || ''}${surname?.charAt(0)?.toUpperCase() || ''}`
    : '';
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

export const getContactValue = (contacts, contactType) => {
  const contact = contacts?.find(item => item?.contact_type === contactType);
  return contact?.contact_value || 'Not provided';
};

export const getStatus = (startDate, endDate) => {
  const currentDate = Date.now();

  if (startDate > currentDate) {
    return 'PENDING';
  } else {
    return 'CURRENT';
  }
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case 'USD':
    case 'usd':
      return '$';
    case 'GBP':
    case 'gbp':
      return '£';
    case 'EUR':
    case 'eur':
      return '€';
    default:
      return '$';
  }
};

export const getRecurringPeriod = period => {
  switch (period) {
    case 'year':
      return 'Yearly';
    case 'month':
      return 'Monthly';
    case 'week':
      return 'Weekly';
  }
};

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;
export const onlyNumbers = /^\d+$/;

export const getFontFamily = font_thickness => {
  switch (font_thickness) {
    case 'REGULAR':
      return { fontFamily: 'Poppins Regular' };
    case 'MEDIUM':
      return { fontFamily: 'Poppins Medium' };
    case 'SEMIBOLD':
      return { fontFamily: 'Poppins Semibold' };
    case 'BOLD':
      return { fontFamily: 'Poppins Bold' };
    default:
      return { fontFamily: 'Poppins Regular' };
  }
};

export const getFontWeight = font_thickness => {
  switch (font_thickness) {
    case 'LIGHT':
      return 300;
    case 'REGULAR':
      return 400;
    case 'MEDIUM':
      return 500;
    case 'SEMIBOLD':
      return 600;
    case 'BOLD':
      return 700;
    default:
      return 400;
  }
};

export const getAlignment = alignment => {
  switch (alignment) {
    case 'LEFT':
      return { justifyContent: 'flex-start', textAlign: 'start' };
    case 'RIGHT':
      return { justifyContent: 'flex-end', textAlign: 'end' };
    case 'CENTER':
      return { justifyContent: 'center', textAlign: 'center' };
    default:
      return { justifyContent: 'center', textAlign: 'unset' };
  }
};

export const getDates = () => {
  const currentMoment = moment().utc().startOf('day');
  const currentDate = currentMoment.get('date');
  const currentMonth = currentMoment.get('month');
  const nextMonth = currentMoment
    .clone()
    .utc()
    .set('month', currentMonth + 1)
    .startOf('day');

  if (currentDate > 21) {
    const nextToNextMonth = currentMoment
      .clone()
      .utc()
      .set('month', currentMonth + 2)
      .startOf('day');
    return { first: nextMonth.clone().set('date', 15), last: nextToNextMonth.clone().startOf('month') };
  }
  if (currentDate < 8) {
    return { first: currentMoment.clone().set('date', 15), last: nextMonth.clone().startOf('month') };
  }
  if (currentDate >= 8 && currentDate <= 21) {
    return { first: nextMonth.clone().startOf('month'), last: nextMonth.clone().set('date', 15) };
  }
  return { first: nextMonth.clone().startOf('month'), last: nextMonth.clone().set('date', 15) };
};

export const getErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  return error?.response?.data?.error_description || defaultErrorText;
};

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getPaymentMethodErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  const errorResponse = error?.response?.data?.response || {};
  let jsonResponse = null;
  jsonResponse = isJson(errorResponse) ? JSON.parse(errorResponse) : errorResponse;
  jsonResponse = isJson(jsonResponse?.response) ? JSON.parse(jsonResponse?.response) : jsonResponse?.response;

  const errorText = jsonResponse?.error_description || defaultErrorText;
  return errorText;
};

export const getComponentsByLexicon = (lexicon, componentsObject) => {
  return componentsObject?.[lexicon] || null;
};

export const getFormattedNumber = (number, currency, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency || 'GBP',
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
};

export const getFormattedNumberStyle = (
  number,
  style = 'currency',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  ...rest
) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-GB', {
    style: style,
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
    ...rest,
  });
  return formatter.format(number);
};

export function calculatePeriodicPaymentAmount(
  principleAmount,
  interestRate = 11.9 / 100 / 12,
  periodValue = 60,
  futureValue = 0,
) {
  const term = Math.pow(1 + interestRate, periodValue);
  return (futureValue * interestRate) / (term - 1) + (principleAmount * interestRate) / (1 - 1 / term);
}
