import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import RightArrow from '../../assets/images/arrow.svg';
import SolarBattery from '../../assets/images/batteries.svg';
import SolarInvertor from '../../assets/images/invertor.svg';
import SolarPanel from '../../assets/images/panel.svg';
import SolarHome from '../../assets/images/solar-home.svg';
import CloseIcon from '../../assets/images/toast-close.svg';
import Button from '../../components/common/button/button';
import Loader from '../../components/common/loader';
import { ModalContext } from '../../context/ModalContext';
import { getFormattedNumber, initModal } from '../../helpers/utils';
import { getProductComponents, updateQuote } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';

const tabList = [
  { id: 'SOLAR_PANELS', label: 'Panels', nextTab: 'SOLAR_INVERTORS', nextTabLabel: 'Continue to Invertors' },
  { id: 'SOLAR_INVERTORS', label: 'Invertors', nextTab: 'SOLAR_BATTERIES', nextTabLabel: 'Continue to Batteries' },
  { id: 'SOLAR_BATTERIES', label: 'Batteries', nextTab: 'SOLAR_ADD_ONS', nextTabLabel: 'Continue to Extras' },
  { id: 'SOLAR_ADD_ONS', label: 'Add-on Extras', nextTabLabel: 'Save configuration' },
];

const getImageFromTab = tab => {
  switch (tab) {
    case 'SOLAR_PANELS':
      return SolarPanel;
    case 'SOLAR_INVERTORS':
      return SolarInvertor;
    case 'SOLAR_BATTERIES':
      return SolarBattery;
    default:
      return SolarPanel;
  }
};

const ModifyConfig = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(ModalContext);
  const { content } = modal || {};
  const { solarOptions, selectedSolarOption, customTheme, api_key, quoteDetails, token } = content || {};
  const { options } = solarOptions || {};
  const { products } = quoteDetails || {};

  const [productComponents, setProductComponents] = useState([]);
  const [selectedTab, setSelectedTab] = useState('SOLAR_PANELS');
  const [currentOtherComponentIndex, setCurrentOtherComponentIndex] = useState(0);
  const [upgradedComponents, setUpgraddedComponents] = useState({});
  const [isUpdatingQuote, setIsUpdatingQuote] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedSolarOption);
  const [isFetchingComponents, setIsFetchingComponents] = useState(true);

  const activeTab = useMemo(() => tabList.find(tab => tab.id === selectedTab), [selectedTab]);

  const { estimate, product } = selectedOption || {};
  const { annual_production_offset_pct } = estimate || {};

  const tabComponents = productComponents?.find(component => component.tag === selectedTab)?.components || [];
  const baseComponent = tabComponents?.find(component => component?.isSelected);
  const otherComponents = tabComponents?.filter(component => !component?.isSelected) || [];
  const currentOtherComponent = otherComponents[currentOtherComponentIndex];
  const dots = new Array(otherComponents.length).fill('_');
  const addOnComponents = useMemo(() => {
    return productComponents?.find(component => component.tag === 'SOLAR_UPGRADES')?.components || [];
  }, [productComponents]);

  const DefaultImageComponent = useMemo(() => {
    return getImageFromTab(selectedTab);
  }, [selectedTab]);

  const optionPercetage = useMemo(() => {
    if (!options) return {};
    return options?.reduce((acc, item) => {
      const { annual_production_offset_pct } = item?.estimate || {};
      acc[annual_production_offset_pct] = item;
      return acc;
    }, {});
  }, [options]);

  const getComponentPrice = (component, format = true) => {
    const { pricing, quantity } = component || {};
    const { prices } = pricing?.[0] || {};
    const { price, currency } = prices?.[0] || {};
    if (!format) return { price: price || 0, currency, quantity: quantity || 1 };
    return getFormattedNumber(price || 0, currency || 'GBP');
  };

  const getPriceDifference = (component, baseComponent) => {
    const { price: componentPrice, currency, quantity: componentQty } = getComponentPrice(component, false);
    const { price: baseComponentPrice, quantity: baseComponentQty } = getComponentPrice(baseComponent, false);
    const priceDiff = componentQty * componentPrice - baseComponentQty * baseComponentPrice;
    return getFormattedNumber(priceDiff, currency);
  };

  const onOptionChange = option => {
    setSelectedOption(option);
  };

  const onPreviousComponent = () => {
    const isDisabled = currentOtherComponentIndex === 0;
    if (isDisabled) return;
    setCurrentOtherComponentIndex(currentOtherComponentIndex - 1);
  };

  const onNextComponent = () => {
    const isDisabled = currentOtherComponentIndex === otherComponents.length - 1;
    if (isDisabled) return;
    setCurrentOtherComponentIndex(currentOtherComponentIndex + 1);
  };

  const setComponentHandler = index => {
    const isDisabled = currentOtherComponentIndex === otherComponents[index];
    if (isDisabled) return;
    setCurrentOtherComponentIndex(index);
  };

  const onUpgradeOrAdd = (component, isAddOn = false) => {
    let newUpgradedComponents = { ...upgradedComponents };
    if (isAddOn) {
      const addedAddOns = upgradedComponents?.addOns || [];
      const isAlreadyAdded = addedAddOns.includes(component);
      if (isAlreadyAdded) {
        const updatedAddOns = addedAddOns.filter(addOn => addOn !== component);
        newUpgradedComponents = { ...upgradedComponents, addOns: updatedAddOns };
      } else {
        newUpgradedComponents = { ...upgradedComponents, addOns: [...addedAddOns, component] };
      }
    } else {
      const isAlreadyAdded = upgradedComponents[selectedTab] && upgradedComponents[selectedTab] === component;
      if (isAlreadyAdded) {
        newUpgradedComponents = { ...upgradedComponents, [selectedTab]: null };
      } else {
        newUpgradedComponents = { ...upgradedComponents, [selectedTab]: component };
      }
    }
    setUpgraddedComponents(newUpgradedComponents);
  };

  const updateProductComponents = (products, productComponents) => {
    const productWithProductIds = products?.filter(p => p?.product?.id);
    const quoteProductComponents = productWithProductIds?.map(p => p?.components)?.flat();

    const newProductComponents = productComponents?.map(component => {
      const { components } = component || {};
      const selectedComponent = components?.filter(c => quoteProductComponents?.find(p => p?.product?.id === c?.id));
      if (selectedComponent?.length) {
        const selectedComponentIds = selectedComponent.map(c => c.id);
        return {
          ...component,
          components: components?.map(c => ({ ...c, isSelected: selectedComponentIds.includes(c.id) })),
        };
      } else {
        return {
          ...component,
          components: components?.map(c => ({ ...c, isSelected: c.sell_type === 'BASE' })),
        };
      }
    });

    const defaultUpgradedComponents = newProductComponents?.reduce((acc, component) => {
      const { components, tag } = component || {};
      if (tag === 'SOLAR_UPGRADES') {
        const selectedComponents = components?.filter(c => c.isSelected);
        return {
          ...acc,
          addOns: selectedComponents,
        };
      }
      const selectedComponent = components?.find(c => c.isSelected);
      return { ...acc, [tag]: selectedComponent };
    }, {});

    setUpgraddedComponents(defaultUpgradedComponents);
    setProductComponents(newProductComponents);
  };

  const fetchProductComponents = async () => {
    setIsFetchingComponents(true);
    dispatch(getProductComponents({ product_id: product?.id, api_key, params: { include_pricing: true } }))
      .then(data => {
        updateProductComponents(products, data);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Failed to fetch product components', id: nanoid() }));
      })
      .finally(() => setIsFetchingComponents(false));
  };

  useEffect(() => {
    fetchProductComponents();

    return () => {
      setProductComponents([]);
      setUpgraddedComponents({});
      setCurrentOtherComponentIndex(0);
      setSelectedTab('SOLAR_PANELS');
    };
  }, [product]);

  const onSaveQuote = () => {
    const {
      SOLAR_PANELS: panelComponent,
      SOLAR_INVERTORS: invertorComponent,
      SOLAR_BATTERIES: batteryComponent,
      addOns,
    } = upgradedComponents;

    const basePanelComponent = productComponents
      ?.find(component => component.tag === 'SOLAR_PANELS')
      ?.components?.find(c => c.isSelected);

    const baseInvertorComponent = productComponents
      ?.find(component => component.tag === 'SOLAR_INVERTORS')
      ?.components?.find(c => c.isSelected);

    const baseBatteryComponent = productComponents
      ?.find(component => component.tag === 'SOLAR_BATTERIES')
      ?.components?.find(c => c.isSelected);

    const panelComponentRequest = panelComponent
      ? [{ id: panelComponent?.id, qty: panelComponent.quantity }]
      : [{ id: basePanelComponent?.id, qty: basePanelComponent.quantity }];

    const invertorComponentRequest = invertorComponent
      ? [{ id: invertorComponent?.id, qty: invertorComponent.quantity }]
      : [{ id: baseInvertorComponent?.id, qty: baseInvertorComponent.quantity }];

    const batteryComponentRequest = batteryComponent
      ? [{ id: batteryComponent?.id, qty: batteryComponent.quantity }]
      : [{ id: baseBatteryComponent?.id, qty: baseBatteryComponent.quantity }];

    const addOnsRequest = (addOns || []).map(addOn => ({ id: addOn.id, qty: addOn.quantity }));

    const componentsRequest = [
      ...panelComponentRequest,
      ...invertorComponentRequest,
      ...batteryComponentRequest,
      ...addOnsRequest,
    ];

    const totalQuantity = componentsRequest.reduce((acc, item) => acc + item.qty, 0);

    const request = {
      products: [
        {
          id: product?.id,
          qty: totalQuantity,
          components: componentsRequest,
        },
      ],
    };
    setIsUpdatingQuote(true);
    dispatch(updateQuote({ quote_id: quoteDetails?.id, request, token }))
      .then(() => {
        setModal(initModal);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Failed to update quote', id: nanoid() }));
      })
      .finally(() => {
        setIsUpdatingQuote(false);
      });
  };

  return (
    <ModifyConfigWrapper customTheme={customTheme} className="flex-column">
      <div className="px-6 pt-8 pb-6 tw-flex tw-flex-col tw-justify-between md:tw-flex-row border-bottom">
        <div className="flex items-center">
          <label className="inter-700-text natural-900-text font-28 flex flex-1">Modify Package</label>
          <div
            className="tw-flex items-center justify-center pxy-1 cursor md:tw-hidden"
            onClick={() => setModal(initModal)}>
            <img src={CloseIcon} />
          </div>
        </div>
        <div>
          <div className="tw-flex gap-2 inter-500-text natural-900-text font-12 line-height-20">
            <p>6 Panel Solar Bundle:</p>
            <p>£6,566.00</p>
          </div>
          <div className="tw-flex gap-2 inter-500-text natural-900-text font-12 line-height-20">
            <p>Upgraded price:</p>
            <p className="color-success_500">£8,4566</p>
          </div>
        </div>
      </div>
      <div className="tw-hidden md:tw-flex items-center justify-center pxy-6">
        <img src={SolarHome} />
      </div>
      <div className="flex tw-items-start tw-flex-col md:tw-flex-row md:tw-items-center pxy-6 col-gap-6">
        <p className="font-16 inter-500-text natural-900-text">Estimated electrical offset: </p>
        <div className="relative bg-primary-100 flex radius-full overflow-auto flex-1 flex tw-mt-2 md:tw-mt-0 tw-w-full md:tw-w-auto">
          {Object.keys(optionPercetage).map(progress => (
            <div key={progress} className="flex-1 cursor" onClick={() => onOptionChange(optionPercetage[progress])}>
              <p
                className={classNames(
                  'tw-px-2 md:tw-px-4 py-1_5 natural-900-text line-height-20 inter-500-text font-12',
                  parseFloat(annual_production_offset_pct) >= parseFloat(progress) && 'bg-primary text-white',
                  parseFloat(annual_production_offset_pct) === parseFloat(progress) &&
                    'text-white rounded-right font-16 inter-600-text',
                )}>
                {progress}%
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center mx-6 tw-overflow-auto tw-whitespace-nowrap border-bottom">
        {tabList.map(tab => (
          <div
            key={tab.id}
            className={classNames('cursor config-tabs pxy-4', selectedTab === tab.id && 'selected-tab')}
            onClick={() => {
              setSelectedTab(tab.id);
              setCurrentOtherComponentIndex(0);
            }}>
            <p
              className={classNames(
                'font-16 inter-500-text natural-400-text',
                selectedTab === tab.id && 'natural-900-text',
              )}>
              {tab.label === 'Add-on Extras' && window.screen.width <= 767
                ? tab.label.replace('Add-on', '')
                : tab.label}
            </p>
          </div>
        ))}
      </div>
      {isFetchingComponents ? (
        <Loader wrapperClassName="pxy-6" />
      ) : (
        <Fragment>
          {selectedTab === 'SOLAR_ADD_ONS' ? (
            <div className={classNames('md:tw-flex md:tw-flex-col row-gap-4 px-6 pb-6 tw-flex tw-flex-wrap mt-6')}>
              {addOnComponents.map(addOn => (
                <div
                  key={addOn.id}
                  className={classNames(
                    'flex items-center radius-3 pxy-4 col-gap-3 border bg-natural-50 tw-flex-wrap',
                    upgradedComponents.addOns?.includes(addOn) && 'bg-primary-50 border-primary-100',
                  )}>
                  <div className="pxy-1 border radius-1_5 flex items-center bg-white">
                    <img className="object-fit-contain h-full" src={addOn?.image?.url} width={80} />
                  </div>
                  <div className="flex-column flex-1 row-gap-1">
                    <label className="inter-700-text font-20 natural-900-text">{addOn?.name}</label>
                    <label className="tw-block font-14 md:tw-hidden inter-500-text natural-900-text">
                      {addOn?.description}
                    </label>
                    <label className="tw-hidden md:tw-block inter-400-text natural-500-text">{addOn?.reason}</label>
                  </div>
                  <p className="tw-block tw-mt-2 tw-mb-4 md:tw-hidden inter-400-text natural-500-text">
                    {addOn?.reason}
                  </p>
                  <div className="tw-flex tw-justify-between max-md:tw-w-full md:tw-flex-col md:tw-items-center row-gap-4">
                    <label className="inter-700-text font-18 natural-900-text">{getComponentPrice(addOn)}</label>
                    <Button
                      label={upgradedComponents.addOns?.includes(addOn) ? 'Selected' : 'Add'}
                      size="medium"
                      className={classNames(
                        'specified-width px-4',
                        upgradedComponents.addOns?.includes(addOn) ? 'btn-secondary' : 'primary',
                      )}
                      width="120px"
                      onClick={() => onUpgradeOrAdd(addOn, true)}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={classNames('p-6')}>
              {/* <div className="border tw-rounded-xl tw-p-4"> */}
              <div
                className={`${
                  upgradedComponents[selectedTab]?.id === currentOtherComponent?.id &&
                  'bg-primary-50 border-primary-100'
                } md:tw-bg-transparent border tw-rounded-t-xl md:tw-rounded-xl tw-p-3 tw-pb-6 tw-items-stretch component-wrapper`}>
                <div
                  className={classNames(
                    'other-component tw-flex tw-flex-row h-full md:tw-px-4 md:tw-py-4 max-md:!tw-border-0 border row-gap-3 radius-3 tw-items-start md:tw-items-start md:p-3 md:tw-h-fit-content col-gap-4',
                  )}>
                  {currentOtherComponent ? (
                    <Fragment>
                      {currentOtherComponent?.image?.url ? (
                        <div className="pxy-1 md:tw-h-full border radius-1_5 flex items-center bg-white ">
                          <img
                            className="object-fit-contain   other-component-img max-md:tw-w-full max-md:tw-aspect-square"
                            src={currentOtherComponent?.image?.url}
                          />
                        </div>
                      ) : (
                        <div className="flex items-center justify-center border radius-3 default-img-wrapper">
                          <DefaultImageComponent width={40} height={40} className="natural-300-text" />
                        </div>
                      )}
                      <div className="md:tw-hidden">
                        <div className="flex tw-mb-2 tw-items-start tw-justify-between tw-gap-5">
                          <label className="md:tw-hidden inter-500-text natural-900-text label-text">
                            {currentOtherComponent?.name}
                          </label>
                          <label className="inter-600-text font-14 natural-900-text flex-column">
                            {getPriceDifference(currentOtherComponent, baseComponent)}
                          </label>
                        </div>
                        <p className="md:tw-hidden  inter-500-text natural-500-text font-12 tw-leading-4">
                          {currentOtherComponent?.description}
                        </p>
                      </div>
                      <div className="tw-hidden md:tw-flex md:tw-flex-col flex-1 md:tw-justify-between h-full">
                        <div className="tw-flex tw-items-start tw-justify-between">
                          <div className="tw-flex tw-flex-col tw-gap-2">
                            <label className="inter-600-text font-16 natural-900-text">
                              {currentOtherComponent?.name}
                            </label>
                            <label className="inter-400-text font-12 natural-400-text">
                              {currentOtherComponent?.description}
                            </label>
                          </div>
                          <div>
                            <label className="inter-700-text font-16 natural-900-text">
                              {getPriceDifference(currentOtherComponent, baseComponent)}
                            </label>
                          </div>
                        </div>
                        <div className="flex-column row-gap-2">
                          <div className="flex items-center justify-between">
                            <Button
                              label={upgradedComponents[selectedTab] === currentOtherComponent ? 'Selected' : 'Upgrade'}
                              size="medium"
                              className={classNames(
                                'specified-width px-4',
                                upgradedComponents[selectedTab] === currentOtherComponent ? 'btn-secondary' : 'primary',
                              )}
                              width="160px"
                              onClick={() => onUpgradeOrAdd(currentOtherComponent)}
                            />
                            {!!(otherComponents.length > 1) && (
                              <div>
                                <div className="tw-flex tw-items-center tw-gap-4 tw-justify-between">
                                  {!!(otherComponents.length > 1) && (
                                    <Button
                                      label=""
                                      icon={<img src={RightArrow} className="tw-w-3 tw-rotate-180 tw-text-white " />}
                                      size="large"
                                      width="24px"
                                      height="24px"
                                      disabled={currentOtherComponentIndex <= 0}
                                      className={classNames('primary p-0 specified-width tw-p-0')}
                                      onClick={() => onPreviousComponent()}
                                    />
                                  )}
                                  {otherComponents.length > 1 && (
                                    <div className="tw-flex tw-justify-center tw-items-center tw-gap-1.5">
                                      {dots.map((_, index) => {
                                        return (
                                          <span
                                            key={index}
                                            onClick={() => setComponentHandler(index)}
                                            className={classNames(
                                              'tw-inline-block tw-h-1.5 tw-w-1.5 tw-rounded-full',
                                              currentOtherComponentIndex === index ? 'bg-primary-400' : 'bg-lightblue',
                                            )}
                                          />
                                        );
                                      })}
                                    </div>
                                  )}
                                  {!!(otherComponents.length > 1) && (
                                    <Button
                                      label=""
                                      afterIcon={<RightArrow className="tw-text-white tw-w-3" />}
                                      size="large"
                                      width="24px"
                                      height="24px"
                                      className={classNames('p-0 primary specified-width')}
                                      disabled={otherComponents.length - 1 <= currentOtherComponentIndex}
                                      onClick={() => onNextComponent()}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="flex items-center justify-center flex-1 col-gap-4">
                      <div className="flex items-center justify-center border radius-3 default-img-wrapper">
                        <DefaultImageComponent width={40} height={40} className="natural-300-text" />
                      </div>
                      <label className="inter-500-text tw-hidden md:tw-flex font-12 natural-400-text flex-1 tw-items-center tw-justify-center">
                        No Upgrades available on this option
                      </label>
                    </div>
                  )}
                </div>
                <div className="tw-col-span-3 md:tw-hidden">
                  {currentOtherComponent && baseComponent ? (
                    <>
                      <div className="tw-flex tw-items-center tw-pt-7  tw-justify-between tw-mb-4 ">
                        {!!(otherComponents.length > 1) && (
                          <Button
                            label=""
                            icon={<RightArrow className="tw-rotate-180 tw-text-white " />}
                            size="large"
                            width="32px"
                            height="32px"
                            disabled={currentOtherComponentIndex <= 0}
                            className={classNames('primary p-0 specified-width tw-p-0')}
                            onClick={() => onPreviousComponent()}
                          />
                        )}
                        <Button
                          label={upgradedComponents[selectedTab] === currentOtherComponent ? 'Selected' : 'Upgrade'}
                          size="medium"
                          className={classNames(
                            'specified-width px-4',
                            upgradedComponents[selectedTab] === currentOtherComponent ? 'btn-secondary' : 'primary',
                          )}
                          width="140px"
                          onClick={() => onUpgradeOrAdd(currentOtherComponent)}
                        />
                        {!!(otherComponents.length > 1) && (
                          <Button
                            label=""
                            afterIcon={<RightArrow className="tw-text-white" />}
                            size="large"
                            width="32px"
                            height="32px"
                            className={classNames('primary specified-width')}
                            disabled={otherComponents.length - 1 <= currentOtherComponentIndex}
                            onClick={() => onNextComponent()}
                          />
                        )}
                      </div>
                      {otherComponents.length > 1 && (
                        <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                          {dots.map((_, index) => {
                            return (
                              <span
                                key={index}
                                onClick={() => setComponentHandler(index)}
                                className={classNames(
                                  'tw-inline-block tw-h-1.5 tw-w-1.5 tw-rounded-full',
                                  currentOtherComponentIndex === index ? 'bg-primary-400' : 'bg-lightblue',
                                )}
                              />
                            );
                          })}
                        </div>
                      )}
                    </>
                  ) : (
                    <p className="font-12 tw-my-4 inter-500-text natural-500-text font-12 tw-leading-4">
                      No Upgrades available on this option
                    </p>
                  )}
                </div>
              </div>

              {/* </div> */}
            </div>
          )}
          <div className="px-8 tw-pt-2 tw-pb-4 tw-flex">
            <Button
              label={activeTab.nextTabLabel}
              size="large"
              width="100%"
              afterIcon={activeTab.nextTab ? <RightArrow /> : null}
              onClick={activeTab.nextTab ? () => setSelectedTab(activeTab.nextTab) : onSaveQuote}
              bgColor={customTheme.color.button}
              borderColor={customTheme.color.button}
              color={theme.white}
              loading={isUpdatingQuote}
            />
          </div>
        </Fragment>
      )}
    </ModifyConfigWrapper>
  );
};

const ModifyConfigWrapper = styled.div`
  max-width: 100%;
  width: 816px;

  * {
    font-family: ${({ customTheme }) => customTheme.font.name || 'Inter'} !important;
  }

  .bg-primary-50 {
    background-color: ${({ customTheme }) => customTheme.color.button}10;
  }

  .border-primary-100 {
    border: 1px solid ${({ customTheme }) => customTheme.color.button}20;
  }

  .btn-secondary {
    background: transparent;
    border: 1px solid ${({ customTheme }) => customTheme.color.button};

    label {
      color: ${({ customTheme }) => customTheme.color.button};
    }
  }

  .config-tabs {
    max-height: 56px;
    height: 100%;
    white-space: normal;
  }

  .empty-tab {
    height: 56px;
  }

  .selected-tab {
    border-bottom: 2px solid ${({ theme }) => theme.natural_900};
  }

  .component-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  .base-component-img {
    height: 120px;
    width: 80px;
  }

  .other-component-img {
    height: 138px;
    width: 91px;
  }

  @media (max-width: 767px) {
    .base-component-img,
    .other-component-img {
      height: 120px;
      min-width: 80px;
    }

    .config-tabs {
      padding: 8px 4px;
    }
  }
  .rounded-right {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .bg-primary {
    background-color: ${({ customTheme }) => customTheme.color.button};
  }

  .disabled-next-prev {
    opacity: 0.5;
  }

  .default-img-wrapper {
    height: 152px;
    width: 103px;
    background-color: ${({ theme }) => theme.natural_100};
  }

  .default-base-img-wrapper {
    height: 120px;
    width: 80px;
    background-color: ${({ theme }) => theme.natural_100};
  }

  .primary {
    background: ${({ customTheme }) => customTheme.color.button} !important;
    border-color: ${({ customTheme }) => customTheme.color.button} !important;
  }
`;

export default ModifyConfig;
