import React, { useState } from 'react';

import Address from '../../components/address';
import { getFontWeight } from '../../helpers/utils';

import styled from 'styled-components';

const variants = {
  size_44: {
    height: '44px',
    padding: '12px 16px',
  },
  size_32: {
    height: '32px',
    padding: '8px 12px',
  },
  size_60: {
    height: '60px',
    padding: '7px 20px',
  },
};

const AddressElement = ({
  name,
  lexicon,
  font,
  is_required = false,
  value,
  defaultValue,
  onChange = () => {},
  placeholder,
  className = '',
  error,
  disabled = false,
  error_text = '',
  extra_text = '',
  autofill = 'off',
  sub_name = '',
  type = 'text',
  variant = 'size_44',
  inputRef,
  extra_text_before = '',
  extra_text_after = '',
  attributes,
}) => {
  const [loadingAddress, setLoadingAddress] = useState(true);
  return (
    <AddressElementtWrapper className={className} variant={variants[variant]}>
      {name && (
        <div className="mb-1 one-line">
          <span
            className="inter-500-text natural-900-text"
            style={{
              color: attributes?.font?.color,
              fontSize: attributes?.font?.size,
              fontWeight: getFontWeight(attributes?.font?.style),
              fontFamily: attributes?.font?.name,
            }}>
            {name}
          </span>
          {/* {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>} */}
          {is_required && (
            <span
              className="required-star"
              style={{
                color: attributes?.font?.color,
                fontSize: attributes?.font?.size,
                fontWeight: getFontWeight(attributes?.font?.style),
                fontFamily: attributes?.font?.name,
              }}>
              *
            </span>
          )}
        </div>
      )}
      <Address
        name={null}
        address={value?.formatted_address}
        setAddress={value => onChange(lexicon, value)}
        loadingAddress={loadingAddress}
        setLoadingAddress={setLoadingAddress}
        font={font}
        variant={variants[variant]}
      />
    </AddressElementtWrapper>
  );
};

const AddressElementtWrapper = styled('div')`
  .input-element {
    outline: none;
    border: none;
    height: ${({ variant }) => variant.height};
    border-radius: 6px;
    padding: ${({ variant }) => variant.padding};

    &::placeholder {
      color: ${({ theme }) => theme.natural_400};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .input-before-element {
    padding-left: 24px !important;
  }
  .percent-before {
    top: 0;
    display: flex;
    align-items: center;
    left: 10px;
    height: 100%;
  }
  .percent-after {
    top: 0;
    display: flex;
    align-items: center;
    right: 10px;
    height: 100%;
  }

  .textarea-element {
    height: 104px;
    resize: none;
  }

  .extra-text {
    right: 20px;
    padding: 12px 0;
  }

  .input-error {
    border-color: ${({ theme }) => theme.error_500};

    &:hover {
      border-color: ${({ theme }) => theme.error_500};
    }

    &:focus {
      border-color: ${({ theme }) => theme.error_500};
      box-shadow: none;
    }
  }
`;

export default AddressElement;
