import React, { Fragment, useState } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ModalContext } from '../context/ModalContext';
import Main from '../pages/main/main';
import Modal from '../popup';
import Toast from '../popup/toast/toast';
import history from './history';

const getRoutes = form =>
  createBrowserRouter([
    {
      path: '*',
      element: <Main form={form} />,
    },
    {
      path: '/:id',
      element: <Main form={form} />,
    },
  ]);

export const Router = ({ form }) => {
  const [modal, setModal] = useState({});

  return (
    <Fragment>
      <ModalContext.Provider value={{ modal, setModal }}>
        <RouterProvider history={history} router={getRoutes(form)} />
        <Modal />
        <Toast />
      </ModalContext.Provider>
    </Fragment>
  );
};
