import React, { Fragment } from 'react';

import InputElement from '../input-element';
import PhoneElement from '../phone-element';
import AddressElement from '../address-element';
import MultiChooseElement from '../multi-choose-element';
import styled from 'styled-components';

const WidgetComponent = ({ component, onChange, value, attributes }) => {
  const componentMap = {
    // NUMERIC: { component: InputElement, props: { type: 'number' } },
    ALPHA: { component: InputElement, props: { variant: 'size_44' } },
    TEXTAREA: { component: InputElement, props: { type: 'textarea', rows: 10, variant: 'size_44' } },
    ADDRESS: { component: AddressElement, props: { variant: 'size_44' } },
    PHONE: { component: PhoneElement, props: { type: 'textarea', variant: 'size_44', countryCode: false } },
    CHOOSERMULTI: { component: MultiChooseElement },
    DEFAULT: { component: () => <Fragment /> },
  };
  const Component =
    componentMap[component.component_layout] || componentMap[component.component_type] || componentMap['DEFAULT'];

  return (
    <WidgetComponentWrapper>
      <Component.component
        {...component}
        placeholder={component.sub_label || component.label || component.name}
        onChange={onChange}
        value={value}
        attributes={attributes}
        {...Component.props}
      />
    </WidgetComponentWrapper>
  );
};

const WidgetComponentWrapper = styled('div')``;

export default WidgetComponent;
