import classNames from 'classnames';
import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import FlagUS from '../../../assets/images/flag-us.svg';
import DropDown from '../dropdown';
import { getFontWeight } from '../../../helpers/utils';

const PhoneInput = ({
  selectedCountry = '+44',
  setSelectedCountry,
  phone,
  setPhone,
  className = '',
  error = false,
  font,
  variant,
  countryCode = true,
}) => {
  const countryList = [
    { name: '+44', value: '+44', icon: 'https://api.dev.zervio.com/icons/d10b5484-ed38-11ee-9378-42010a9a0011/active' },
    { name: '+1', value: '+1', icon: 'https://api.dev.zervio.com/icons/d10b5484-ed38-11ee-9378-42010a9a0012/active' },
  ];

  const maskField = {
    '+1': [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    '+44': [/\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
  };

  return (
    <PhoneInputWrapper className={`flex relative ${className}`} variant={variant}>
      {countryCode && (
        <DropDown
          options={countryList}
          icon={true}
          getIcon={option => option.icon}
          className="absolute h-full phone-input-dropdown"
          placeholder={'+1'}
          selected={countryList.find(c => c.value === selectedCountry)}
          setSelected={country => setSelectedCountry(country.value)}
        />
      )}
      <MaskedInput
        mask={maskField[selectedCountry]}
        value={phone || null}
        className={classNames('phone-input w-full phone-input', error && 'error-border')}
        placeholder="00000-000-000"
        guide={false}
        onChange={({ target: { value } }) => setPhone(value)}
        style={{
          color: font?.color,
          fontSize: font?.size,
          fontWeight: getFontWeight(font?.style),
          fontFamily: font?.name,
          paddingLeft: countryCode ? '110px' : '',
        }}
      />
    </PhoneInputWrapper>
  );
};

const PhoneInputWrapper = styled('div')`
  height: ${({ variant }) => variant.height};

  .phone-input {
    border-radius: 6px;
    border: none;
    color: ${({ theme }) => theme.darkBlue};
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    outline: none;
    padding: ${({ variant }) => variant.padding};
  }
  .phone-input-dropdown {
    .organisations-border {
      border: none;
      height: 100%;
      width: 80px;
      box-sizing: content-box;
    }

    .option-name,
    .selected-name {
      font-size: 16px;
    }

    .mc-option-item:hover label {
      color: ${({ theme }) => theme.RegularText};
    }
  }
`;

export default PhoneInput;
