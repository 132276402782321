import React, { useState } from 'react';

import PhoneInput from '../common/phone-input';
import { getFontWeight } from '../../helpers/utils';

import styled from 'styled-components';

const variants = {
  size_44: {
    height: '44px',
    padding: '12px 16px',
  },
  size_32: {
    height: '32px',
    padding: '8px 12px',
  },
  size_60: {
    height: '60px',
    padding: '7px 20px',
  },
};

const PhoneElement = ({
  name,
  lexicon,
  font,
  is_required = false,
  value,
  defaultValue,
  onChange = () => {},
  placeholder,
  className = '',
  error,
  disabled = false,
  error_text = '',
  extra_text = '',
  autofill = 'off',
  sub_name = '',
  type = 'text',
  variant = 'size_44',
  inputRef,
  extra_text_before = '',
  extra_text_after = '',
  attributes,
  countryCode,
}) => {
  return (
    <PhoneElementtWrapper className={className} variant={variants[variant]}>
      {name && (
        <div className="mb-1 one-line">
          <span
            className="inter-500-text natural-900-text"
            style={{
              color: attributes?.font?.color,
              fontSize: attributes?.font?.size,
              fontWeight: getFontWeight(attributes?.font?.style),
              fontFamily: attributes?.font?.name,
            }}>
            {name}
          </span>
          {/* {sub_name && <span className="inter-400-text natural-400-text ml-1">{sub_name}</span>} */}
          {is_required && (
            <span
              className="required-star"
              style={{
                color: attributes?.font?.color,
                fontSize: attributes?.font?.size,
                fontWeight: getFontWeight(attributes?.font?.style),
                fontFamily: attributes?.font?.name,
              }}>
              *
            </span>
          )}
        </div>
      )}
      <PhoneInput
        selectedCountry={value?.country_code || '+44'}
        setSelectedCountry={v => onChange(lexicon, { ...value, country_code: v })}
        phone={value?.value}
        setPhone={v => onChange(lexicon, { ...value, value: v })}
        font={font}
        variant={variants[variant]}
        countryCode={countryCode}
      />
    </PhoneElementtWrapper>
  );
};

const PhoneElementtWrapper = styled('div')``;

export default PhoneElement;
